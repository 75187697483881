.pine-spinner {
  height: 200px;
  width: 200px;
  position: relative;
}

.pine-spinner svg {
  position: absolute;
}

.pine-spinner svg.blur {
  filter: blur(20px);
}

.pine-spinner svg polygon {
  animation-iteration-count: infinite;
  animation-name: dash;
  animation-timing-function: linear;
  stroke-dasharray: 600;
}

.pine-spinner svg polygon.first-stroke {
  animation-delay: 0s;
}

.pine-spinner svg polygon.second-stroke {
  animation-delay: .1s;
}

.pine-spinner svg polygon.third-stroke {
  animation-delay: .2s;
}
  
@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1200;
  }
}